<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { cloneDeep, compact, orderBy, uniqBy } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import InspectionForm from '~/forms/components/new-form/terra-workflow-form.vue';
import WorkflowTemplateDetails from '~/terra/components/workflow/workflow-form-details.vue';

const props = defineProps({
  is_edit: {
    type: Boolean,
    default: false,
  },
  workflow: {
    type: Object,
  },
});
const emit = defineEmits(['close']);
const auth_store = useAuthStore();
const terra_store = useTerraStore();
const route = useRoute();
const $toast = inject('$toast');

const { getStyles } = useTerraHelperComposable();

const inspection_form = useModal({
  component: InspectionForm,
  attrs: {
    onClose() {
      inspection_form.close();
    },
  },
});

const form = ref(null);
const prev_field_state = ref({});
const loading = ref(false);
const show_suggestions = ref('');
const form_integrated_fields = ref({});

const feature_types = computed(() => {
  return terra_store.ftg.reduce((acc, curr) => {
    if (curr.featureTypes)
      return acc.concat(curr.featureTypes.filter(val => val.uid).map((val) => {
        return { value: val.uid, label: val.name, color: val.properties.color, feature: val };
      }));
    return acc;
  }, []);
});
const get_field_suggestions = computed(() => {
  const all_fields = form.value.steps.reduce((acc, curr) => {
    acc.push(...curr.fields);
    return acc;
  }, []);
  const suggestions = {};
  for (let i = 0; i < form.value.steps.length; i++) {
    const curr_fields_uids = form.value.steps[i].fields.map(val => val.uid);
    suggestions[i] = uniqBy(all_fields.filter(val => !curr_fields_uids.includes(val.uid) && !val.is_created && val.name), e => e.uid);
  }
  return suggestions;
});

const filter_sources = computed(() => {
  return form.value.steps.map(val => val.source).filter(val => val !== null);
});
const filter_destination = computed(() => {
  return form.value.steps.map(val => val.destination).filter(val => val !== null);
});
const is_fields_100_percent = computed(() => {
  return (
    form.value?.fields?.filter(field => !field.is_created).reduce((acc, field) => {
      if (field.is_editing)
        return acc + Number(prev_field_state.value[field.uid]?.weight);
      return acc + Number(field.weight);
    }, 0) === 100
  );
});
const is_fields_present_in_steps = computed(() => {
  return form.value.steps.filter(step => step.fields.filter(field => !field.is_created && !field.is_editing).length === 0).length === 0;
});
const is_disabled = computed(() => {
  if (!terra_store.check_permission('modify_workflows'))
    return true;
  if (form.value.progress_type === 'units')
    return !(is_fields_present_in_steps.value);
  return !(is_fields_100_percent.value);
});

function openInspectionForm(step_uid) {
  inspection_form.patchOptions({
    attrs: {
      form_data: form_integrated_fields.value[step_uid],
      onSave(form) {
        let uid = crypto.randomUUID();
        if (form_integrated_fields.value[step_uid])
          uid = form_integrated_fields.value[step_uid].uid;
        const field = {
          uid,
          name: form.name,
          type: 'INTG_101',
          units: 'units',
          config: {
            template: form.template,
            assignees: form.assignees,
            duration: form.duration,
            required: form.required,
          },
        };
        form_integrated_fields.value[step_uid] = field;
        inspection_form.close();
      },
    },
  });
  inspection_form.open();
}

function initForm() {
  if (props.is_edit) {
    const progress_type = props.workflow.data[
      Object.keys(props.workflow.data)[0]
    ]?.progress_type || 'units';

    form.value = {
      name: props.workflow.name,
      progress_type,
      workflow_in_all_assets: !props.workflow.asset,
    };
    if (progress_type === 'units') {
      const steps = Object.keys(props.workflow.data).map((source) => {
        const step = props.workflow.data[source];
        const uid = crypto.randomUUID();
        return {
          uid,
          source,
          ...step,
          fields: step.fields.reduce((acc, field) => {
            if (field.type === 'INTG_101')
              form_integrated_fields.value[uid] = field;
            else
              acc.push({
                ...field,
                required: field.uid in step.required,
                weight: Number.parseFloat(((step.required[field.uid] ?? 0) * 100).toFixed(8)),
                is_created: false,
                is_editing: false,
              });
            return acc;
          }, []),
        };
      });
      form.value.steps = steps.sort((a, b) => a?.order_index - b?.order_index);
    }
    else {
      const first = Object.values(props.workflow.data)[0];
      if (!first)
        return;

      const fields = first.fields.map((field) => {
        return {
          ...field,
          weight: Number.parseFloat(((first.weights[field.uid]) * 100).toFixed(8)),
        };
      });
      const steps = Object.entries(first.destination).map(
        ([destination, [min, max]]) => {
          return {
            min: Number.parseFloat((min * 100).toFixed(8)),
            max: Number.parseFloat((max * 100).toFixed(8)),
            destination,
          };
        },
      );
      form.value.fields = fields;
      form.value.steps = orderBy(steps, a => a.min);
    }
  }
  else {
    form.value = {
      name: '',
      progress_type: 'units',
      workflow_in_all_assets: false,
      steps: [
        {
          uid: crypto.randomUUID(),
          source: null,
          destination: null,
          fields: [],
          order_index: 1,
        },
      ],
    };
    addField(0);
  }
}

function updateForm() {
  if (form.value.progress_type === 'units') {
    delete form.value.fields;
    form.value.steps = [
      {
        uid: crypto.randomUUID(),
        source: null,
        destination: null,
        fields: [],
        order_index: form.value.steps.length + 1,
      },
    ];
    addField(0);
  }
  else {
    delete form.value.steps;
    form.value.steps = [];
    form.value.fields = [];
  }
}

function addField(index) {
  if (form.value.progress_type === 'units') {
    const fields = form.value.steps[index].fields.filter(field => field.is_created || field.is_editing);
    if (fields.length && form.value.steps[index].fields.length > 0)
      return;
    const uid = crypto.randomUUID();
    form.value.steps[index].fields.push(
      {
        uid,
        name: '',
        required: true,
        weight: 100,
        type: 'number',
        units: 'nos',
        is_created: true,
        is_editing: false,
      },
    );
  }
  else {
    const fields = form.value.fields.filter(field => field.is_created || field.is_editing);
    if (fields.length && form.value.fields.length > 0)
      return;
    const total_weight = form.value.fields.reduce((acc, curr) => {
      acc += Number(curr.weight);
      return acc;
    }, 0);
    const uid = crypto.randomUUID();
    form.value.fields.push(
      {
        uid,
        name: '',
        weight: 100 - total_weight,
        type: 'number',
        units: 'nos',
        is_created: true,
        is_editing: false,
      },
    );
  }
}

function getField(step_index, field_index) {
  return form.value.steps[step_index].fields[field_index];
}

function addStep() {
  if (form.value.progress_type === 'units') {
    form.value.steps.push(
      {
        uid: crypto.randomUUID(),
        source: null,
        destination: null,
        fields: [],
        order_index: form.value.steps.length + 1,
      },
    );
  }
  else {
    let min = 0;
    let max = 1;
    if (form.value.steps.length !== 0) {
      min = form.value.steps.slice(-1)[0].max;
      max = min + 1;
    }
    if (min > 100)
      return;

    form.value.steps.push(
      {
        min,
        max,
        destination: null,
      },
    );
  }
}

function addOrUpdateField(step_index, field_index) {
  const field = form.value.progress_type === 'units' ? getField(step_index, field_index) : form.value.fields[step_index];
  if (field.name && field.weight !== null)
    if (field.is_created) {
      if (form.value.progress_type === 'units')
        form.value.steps[step_index].fields[field_index].is_created = false;
      else
        form.value.fields[step_index].is_created = false;
    }
    else if (field.is_editing) {
      handleUpdateField(step_index, field_index);
      delete prev_field_state[field.uid];
    }
    else if (!field.is_editing) {
      if (form.value.progress_type === 'units')
        form.value.steps[step_index].fields[field_index].is_editing = true;
      else
        form.value.fields[step_index].is_editing = true;
      prev_field_state.value[field.uid] = field;
    }
}

function handleUpdateField(step_index, field_index) {
  if (form.value.progress_type === 'units') {
    form.value.steps[step_index].fields[field_index].is_editing = false;
    const updated_field = form.value.steps[step_index].fields[field_index];
    for (let i = 0; i < form.value.steps.length; i++) {
      if (step_index === i)
        continue;
      form.value.steps[i].fields = form.value.steps[i].fields.map((val) => {
        if (val.uid === updated_field.uid)
          return cloneDeep({ ...val, name: updated_field.name });
        return val;
      });
    }
  }
  else {
    form.value.fields[step_index].is_editing = false;
  }
}

function validateStepMin(index) {
  if (!form.value.steps[index])
    return;
  const value = Number(form.value.steps[index].min);
  let min_val = 0;
  if (index > 0)
    min_val = form.value.steps[index - 1].max;

  if (value < min_val || value < 0)
    form.value.steps[index].min = min_val;

  else
    form.value.steps[index].min = value;
}

function validateStepMax(index) {
  if (!form.value.steps[index])
    return;
  const value = Number(form.value.steps[index].max);
  const min_val = form.value.steps[index].min + 1;
  const max_val = 101;

  if (value > max_val || value < min_val)
    form.value.steps[index].max = min_val;

  else
    form.value.steps[index].max = value;
}

function pasteFields(evt, step_index, field_index) {
  const string = evt.clipboardData.getData('text');

  let items = string.split(/[\n,]+/);

  let fields = [];
  if (form.value.progress_type !== 'units')
    fields = form.value.fields;
  else
    fields = form.value.steps[step_index].fields;
  fields.splice(field_index, 1);

  items = compact(items.map(item => item.trim()));
  if (!items.length)
    return;

  evt.preventDefault();

  updatePasteFields(step_index, items, fields);
}

function updatePasteFields(step_index, items, updated_fields) {
  const fields = updated_fields;
  for (const item of items) {
    const [name, weight, units] = item.split(/[\t,]+/);
    const index = fields.findIndex(val => val.name === name);
    let uid = crypto.randomUUID();

    if (step_index !== null && index === -1) {
      const suggestion_index = get_field_suggestions.value[step_index].findIndex(val => val.name === name);
      if (suggestion_index !== -1)
        uid = get_field_suggestions.value[step_index][suggestion_index].uid;
    }
    const payload = {
      uid,
      name,
      weight: weight || 100,
      units: units || '',
      type: 'number',
      is_created: false,
      is_editing: false,
      ...(form.value.progress_type === 'units' ? { required: true } : {}),
    };
    if (index === -1)
      fields.push(payload);
  }
  if (form.value.progress_type !== 'units')
    form.value.fields = fields;
  else
    form.value.steps[step_index].fields = fields;
}

function getFieldsData(fields) {
  return fields.filter(field => !field.is_created).map((field) => {
    const curr_field = field.is_editing ? prev_field_state.value[field.uid] : field;
    return {
      uid: curr_field.uid,
      name: curr_field.name,
      type: curr_field.type,
      units: curr_field.units,
    };
  });
}

function addSuggestion(suggestion, step_index, field_index) {
  form.value.steps[step_index].fields[field_index] = { ...suggestion, is_created: false, is_editing: false };
  show_suggestions.value = '';
}

function numberInputValidator(e, step_index, field_index, name, allow_float = false) {
  if (!e.target.value)
    return;
  const value = allow_float ? e.target.value : Number(e.target.value);
  let value_to_load = name === 'min' ? 0 : 1;
  if (value >= value_to_load && (allow_float || Number.isInteger(value)))
    value_to_load = value;
  const maxValue = name === 'max' ? 101 : 100;
  if (value > maxValue)
    value_to_load = maxValue;
  if (step_index !== null)
    if (field_index !== null)
      form.value.steps[step_index].fields[field_index][name] = value_to_load;
    else
      form.value.steps[step_index][name] = value_to_load;
  else
    form.value.fields[field_index][name] = value_to_load;
}

function genUnitTypePayload() {
  return form.value.steps.reduce((acc, step) => {
    const { source, destination, fields, order_index } = step;
    const required = fields
      .filter(field => field.required)
      .reduce((acc, field) => {
        acc[field.uid] = field.weight / 100;
        return acc;
      }, {});
    const fields_data = getFieldsData(fields);
    const field_form_int = form_integrated_fields.value[step.uid];
    if (field_form_int) {
      fields_data.push(field_form_int);
      if (field_form_int.config.required)
        required[field_form_int.uid] = 1;
    }

    acc[source] = {
      fields: fields_data,
      destination,
      required,
      order_index,
    };
    return acc;
  }, {});
}

function genPercentageTypePayload() {
  const data = {};
  const { fields, steps } = form.value;
  const progress_type = form.value.progress_type;
  const destination = steps.reduce((acc, step) => {
    acc[step.destination] = [step.min / 100, step.max / 100];
    return acc;
  }, {});
  const weights = fields.reduce((acc, field) => {
    acc[field.uid] = field.weight / 100;
    return acc;
  }, {});
  const payload = {
    fields: getFieldsData(fields),
    weights,
    destination,
    progress_type,
  };
  for (const source in destination)
    data[source] = payload;

  return data;
}

async function createOrUpdateWorkflow() {
  loading.value = true;
  const data = form.value.progress_type === 'units' ? genUnitTypePayload() : genPercentageTypePayload();
  const payload = {
    name: form.value.name,
    asset: form.value.workflow_in_all_assets
      ? null
      : route.params.asset_id,
    data,
  };

  Object.keys(data).forEach((source) => {
    let next_destination = data[source].destination;
    const destination_path = [next_destination];

    let step = 1;
    while (step)

      if (data[next_destination] && !destination_path.includes(source)) {
        destination_path.push(data[next_destination].destination);
        next_destination = data[next_destination].destination;
        step++;
      }
      else {
        step = 0;
      }

    if (destination_path.length > 1 && destination_path.includes(source)) {
      $toast({
        title: 'Something went wrong',
        text: 'Added steps forms a loop, please check again and fix',
        type: 'error',
        timeout: 20000,
        position: 'top-center',

      });
      throw new Error('Added steps forms a loop, check again');
    }
  });

  if (props.is_edit)
    await terra_store.update_workflow({
      body: payload,
      id: props.workflow.uid,
      asset_id: route.params.asset_id,
    });
  else
    await terra_store.create_workflow({
      body: payload,
      asset_id: route.params.asset_id,
    });
  $toast({
    text: `Workflow ${props.is_edit ? 'updated' : 'created'} successfully`,
    type: 'success',
  });
  terra_store.terra_track_events(`Workflow ${props.is_edit ? 'updated' : 'created'}`);

  loading.value = false;
  emit('close');
}

initForm();
</script>

<template>
  <hawk-modal-container :width="800" content_class="rounded-lg !w-[800px]">
    <Vueform v-model="form" sync size="sm" :display-errors="false" :endpoint="createOrUpdateWorkflow">
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ is_edit ? $t("Updating Workflow") : $t("New Workflow") }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            class="my-4"
            name="name"
            :disabled="!terra_store.check_permission('modify_workflows')"
            :placeholder="$t('Workflow name')"
            :label="$t('Name')"
            :add-classes="{
              ElementLabel: {
                wrapper: 'font-medium',
              },
            }"
            :rules="[
              'required',
            ]"
            :columns="{
              lg: {
                container: 12,
                label: 4,
                wrapper: 12,
              },
            }"
          />
          <SelectElement
            class="my-4"
            name="progress_type"
            :label="$t('Progress Type')"
            default="units"
            :disabled="is_edit"
            :rules="[
              'required',
            ]"
            :add-classes="{
              ElementLabel: {
                wrapper: 'font-medium',
              },
            }"
            :placeholder="$t('Select')"
            :items="[
              {
                label: $t('Units Completion'),
                value: 'units',
              },
              {
                label: $t('Percentage Completion'),
                value: 'cumulative',
              },
            ]"
            :can-clear="false"
            :can-deselect="false"
            :native="false"
            :columns="{
              lg: {
                container: 12,
                label: 4,
                wrapper: 12,
              },
            }"
            @change="updateForm"
          />
          <CheckboxElement :disabled="!terra_store.check_permission('modify_workflows')" name="workflow_in_all_assets" class="my-4">
            {{ $t('Allow using this workflow in all assets') }}
          </CheckboxElement>

          <div class="my-3">
            <div class="text-[16px] font-semibold mb-1">
              {{ $t('Configuration') }}
            </div>
            <div class="text-[13px] text-gray-600">
              {{ $t('Add fields to be tracked, assign weights and configure feature types to reach at each percentage steps') }}
            </div>
          </div>
          <ListElement
            v-if="form.progress_type === 'units'"
            :rules="[
              'required',
            ]"
            name="steps"
            :add-classes="{
              ListElement: {
                listItem: 'px-4 pt-2 bg-gray-50 mb-2',
              },
            }"
            :controls="{
              add: false,
              remove: false,
            }"
          >
            <template #default="{ index }">
              <ObjectElement
                :name="index"
              >
                <div class="col-span-12">
                  <div class="grid grid-cols-3 gap-3">
                    <div>
                      <SelectElement
                        name="source"
                        :label="$t('Source')"
                        :placeholder="$t('Select')"
                        :native="false"
                        :disabled="!terra_store.check_permission('modify_workflows')"
                        :items="feature_types"
                        :rules="[
                          'required',
                        ]"
                        :close-on-select="true"
                        :add-classes="{
                          SelectElement: {
                            select: {
                              dropdown: '!overflow-y-auto',
                              options: '!none !hidden !h-0',
                              noOptions: '!none !hidden !h-0',
                              noResults: '!none !hidden !h-0',
                            },
                          },
                          ElementLayout: {
                            outerWrapper: '!block',
                          },
                          ElementLabel: {
                            container: '!mb-2.5',
                            wrapper: 'font-medium',
                          },
                        }"
                      >
                        <template #before-list="{ el$ }">
                          <FeatureTypes
                            type="dropdown"
                            :dropdown_value="terra_store.feature_types_by_uid[form.steps[index].source] || (index > 0 && terra_store.feature_types_by_uid[form.steps[index - 1].destination])"
                            :filter_features_type="[form.steps[index].destination, ...filter_sources.filter(val => val !== form.steps[index].source)]"
                            @select="($event) => {
                              form.steps[index].source = $event.uid;
                              el$?.input?.close();
                            }"
                          />
                        </template>
                        <template #single-label="{ value }">
                          <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                            <div
                              class="cursor-pointer w-3 h-3 rounded-full bg-gray-400"
                              :style="getStyles(value.feature)"
                            />

                            <p
                              class="cursor-pointer pointer-events-auto whitespace-nowrap text-[13px] text-gray-600 ml-2"
                            >
                              <HawkText :content="value.label" :length="20" />
                            </p>
                          </div>
                        </template>
                      </SelectElement>
                    </div>
                    <div>
                      <SelectElement
                        name="destination"
                        :label="$t('Destination')"
                        :disabled="!terra_store.check_permission('modify_workflows')"
                        :placeholder="$t('Select')"
                        :native="false"
                        :items="feature_types"
                        :rules="[
                          'required',
                        ]"
                        :close-on-select="true"
                        :add-classes="{
                          SelectElement: {
                            select: {
                              dropdown: '!overflow-y-auto',
                              options: '!none !hidden !h-0',
                              noOptions: '!none !hidden !h-0',
                              noResults: '!none !hidden !h-0',
                            },
                          },
                          ElementLayout: {
                            outerWrapper: '!block',
                          },
                          ElementLabel: {
                            container: '!mb-2.5',
                            wrapper: 'font-medium',
                          },
                        }"
                      >
                        <template #before-list="{ el$ }">
                          <FeatureTypes
                            type="dropdown"
                            :dropdown_value="terra_store.feature_types_by_uid[form.steps[index].destination] || terra_store.feature_types_by_uid[form.steps[index].source]"
                            :filter_features_type="[form.steps[index].source, ...filter_destination.filter(val => val !== form.steps[index].destination)]"
                            @select="($event) => {
                              form.steps[index].destination = $event.uid;
                              el$?.input?.close();
                            }"
                          />
                        </template>
                        <template #single-label="{ value }">
                          <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                            <div
                              class="cursor-pointer w-3 h-3 rounded-full bg-gray-400"
                              :style="getStyles(value.feature)"
                            />

                            <p
                              class="cursor-pointer pointer-events-auto whitespace-nowrap text-[13px] text-gray-600 ml-2"
                            >
                              <HawkText :content="value.label" :length="20" />
                            </p>
                          </div>
                        </template>
                      </SelectElement>
                    </div>
                  </div>
                </div>
                <div class="col-span-12">
                  <div class="flex flex-wrap gap-4 items-center mt-2 text-[14px] font-medium text-gray-700">
                    <div class="w-[270px]">
                      {{ $t('Name') }}
                    </div>
                    <div class="w-[60px]">
                      {{ $t('Required') }}
                    </div>
                    <div class="w-[90px]">
                      {{ $t('% needed') }}
                    </div>
                    <div class="w-[140px]">
                      {{ $t('Units') }}
                    </div>
                    <div v-if="terra_store.check_permission('modify_workflows')" class="w-[80px]">
                      {{ $t('Actions') }}
                    </div>
                  </div>
                </div>

                <ListElement
                  name="fields"
                  :sort="true"
                  :rules="[
                    'required',
                  ]"
                  :controls="{
                    add: false,
                    remove: false,
                  }"
                >
                  <template #default="field">
                    <ObjectElement :name="field.index">
                      <div class="col-span-12">
                        <div class="flex flex-wrap gap-4 items-start mb-3">
                          <div class="w-[270px]">
                            <TextElement
                              name="name"
                              :placeholder="$t('Enter name')"
                              :rules="[
                                'required',
                              ]"
                              :disabled="!(getField(index, field.index).is_created || getField(index, field.index).is_editing)"
                              autocomplete="off"
                              @paste="pasteFields($event, index, field.index)"
                              @keypress.enter="addOrUpdateField(index, field.index)"
                              @click="($event) => {
                                if ((getField(index, field.index).is_created || getField(index, field.index).is_editing) && $event.target.focus)
                                  show_suggestions = getField(index, field.index).uid;
                              }"
                            >
                              <template #description>
                                <div v-show="getField(index, field.index).uid === show_suggestions && (get_field_suggestions[index] || []).length" class="absolute w-[270px] max-h-[120px] bg-white z-20 p-1 border rounded-lg overflow-auto">
                                  <div
                                    v-for="suggestion in (get_field_suggestions[index] || []).filter(val => !(getField(index, field.index).name || '') || val.name.toLowerCase().includes((getField(index, field.index).name || '').toLowerCase()))"
                                    :key="suggestion.uid"
                                    class="p-[10px] pb-[8px] rounded-lg font-medium cursor-pointer hover:bg-gray-50"
                                    @click.stop="addSuggestion(suggestion, index, field.index)"
                                  >
                                    {{ suggestion.name }}
                                  </div>
                                </div>
                              </template>
                            </TextElement>
                          </div>
                          <div class="w-[60px] mt-2">
                            <CheckboxElement
                              name="required"
                              :disabled="!(getField(index, field.index).is_created || getField(index, field.index).is_editing)"
                            />
                          </div>
                          <div class="w-[90px]">
                            <TextElement
                              name="weight"
                              input-type="number"
                              :rules="[
                                'numeric',
                              ]"
                              :disabled="!(getField(index, field.index).is_created || getField(index, field.index).is_editing)"
                              autocomplete="off"
                              @input="$event => numberInputValidator($event, index, field.index, 'weight', true)"
                              @blur="form.steps[index].fields[field.index].weight = Number.parseFloat(form.steps[index].fields[field.index].weight)"
                              @keypress.enter="addOrUpdateField(index, field.index)"
                            />
                          </div>
                          <div class="w-[140px]">
                            <TextElement
                              name="units"
                              :disabled="!(getField(index, field.index).is_created || getField(index, field.index).is_editing)"
                              @keypress.enter="addOrUpdateField(index, field.index)"
                            />
                          </div>
                          <div v-if="terra_store.check_permission('modify_workflows')" class="w-[80px]">
                            <div class="flex items-center justify-between">
                              <div
                                class="rounded-md cursor-pointer flex items-center justify-center text-base text-gray-500 font-semibold hover:bg-gray-200 w-8 h-8"
                                @click="addOrUpdateField(index, field.index)"
                              >
                                <IconHawkCheckTwo v-if="getField(index, field.index).is_created || getField(index, field.index).is_editing" class="w-4 h-4" />
                                <IconHawkPencilOne v-else class="w-4 h-4" />
                              </div>
                              <div
                                class="rounded-md cursor-pointer flex items-center justify-center hover:bg-gray-200 w-8 h-8 mx-2"
                                @click="form.steps[index].fields.splice(field.index, 1)"
                              >
                                <IconHawkTrashThree class="w-4 h-4" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ObjectElement>
                  </template>
                </ListElement>
              </ObjectElement>
              <div class="col-span-12">
                <div v-if="terra_store.check_permission('modify_workflows')" class="flex justify-between items-center mb-4">
                  <div class="flex items-center">
                    <div
                      class="group flex items-center cursor-pointer text-primary-700 hover:bg-primary-50 w-28 rounded-lg p-2"
                      :class="{ 'opacity-50 pointer-events-none': form.steps[index].fields.filter(field => field.is_created || field.is_editing).length !== 0 }"
                      @click="addField(index)"
                    >
                      <IconHawkPlus
                        class="text-primary-700 w-4 h-4 mr-2"
                      />
                      <div class="text-[14px] font-semibold group-hover:text-primary-800">
                        {{ $t('Add field') }}
                      </div>
                    </div>
                    <div
                      v-if="auth_store.check_split('intg_101') && !form_integrated_fields[form.steps[index].uid]"
                      class="group flex items-center cursor-pointer text-primary-700 hover:bg-primary-50 rounded-lg p-2"
                      @click="openInspectionForm(form.steps[index].uid)"
                    >
                      <IconHawkPlus
                        class="text-primary-700 w-4 h-4 mr-2"
                      />
                      <div class="text-[14px] font-semibold group-hover:text-primary-800">
                        {{ $t('Add Inspection Form') }}
                      </div>
                    </div>
                  </div>
                  <div class="text-[14px] font-semibold cursor-pointer text-gray-600 hover:bg-gray-200 hover:text-gray-700 rounded-lg p-2" @click="form.steps.splice(index, 1)">
                    {{ $t('Delete step') }}
                  </div>
                </div>
                <WorkflowTemplateDetails
                  v-if="auth_store.check_split('intg_101') && form_integrated_fields[form.steps[index].uid]"
                  class="mb-4"
                  show_template_info
                  :field_details="form_integrated_fields[form.steps[index].uid]"
                  @edit="openInspectionForm(form.steps[index].uid)"
                  @delete="form_integrated_fields[form.steps[index].uid] = null"
                />
              </div>
            </template>
          </ListElement>
          <template v-else>
            <div class="bg-gray-50 p-4 rounded-lg">
              <div class="col-span-12 mb-2">
                <div class="flex flex-wrap gap-4 items-center mt-2 text-[14px] font-medium text-gray-700">
                  <div class="w-[350px]">
                    {{ $t('Name') }}
                  </div>
                  <div class="w-[90px]">
                    {{ $t('Weight') }} (%)
                  </div>
                  <div class="w-[140px]">
                    {{ $t('Units') }}
                  </div>
                  <div class="w-[80px]">
                    {{ $t('Actions') }}
                  </div>
                </div>
              </div>

              <ListElement
                name="fields"
                :sort="true"
                :rules="[
                  'required',
                ]"
                :add-classes="{
                  ListElement: {
                    listItem: 'bg-gray-50',
                  },
                }"
                :controls="{
                  add: false,
                  remove: false,
                }"
              >
                <template #default="{ index }">
                  <ObjectElement :name="index">
                    <div class="col-span-12">
                      <div class="flex flex-wrap gap-4 items-start mb-3">
                        <div class="w-[350px]">
                          <TextElement
                            name="name"
                            :placeholder="$t('Enter name')"
                            :rules="[
                              'required',
                            ]"
                            :disabled="!(form.fields[index].is_created || form.fields[index].is_editing)"
                            @paste="pasteFields($event, null, index)"
                            @keypress.enter="addOrUpdateField(index)"
                          />
                        </div>
                        <div class="w-[90px]">
                          <TextElement
                            name="weight"
                            input-type="number"
                            :rules="[
                              'numeric',
                            ]"
                            :disabled="!terra_store.check_permission('modify_workflows') || !(form.fields[index].is_created || form.fields[index].is_editing)"
                            autocomplete="off"
                            @input="$event => numberInputValidator($event, null, index, 'weight', true)"
                            @blur="form.fields[index].weight = Number.parseFloat(form.fields[index].weight)"
                            @keypress.enter="addOrUpdateField(index)"
                          />
                        </div>
                        <div class="w-[140px]">
                          <TextElement
                            name="units"
                            :disabled="!(form.fields[index].is_created || form.fields[index].is_editing)"
                            @keypress.enter="addOrUpdateField(index)"
                          />
                        </div>
                        <div v-if="terra_store.check_permission('modify_workflows')" class="w-[80px]">
                          <div class="flex items-center">
                            <div
                              class="rounded-md cursor-pointer text-base text-gray-500 font-semibold  flex items-center justify-center hover:bg-gray-200 w-8 h-8 mr-2"
                              @click="addOrUpdateField(index)"
                            >
                              <IconHawkCheckTwo v-if="form.fields[index].is_created || form.fields[index].is_editing" class="w-4 h-4" />
                              <IconHawkPencilOne v-else class="w-4 h-4" />
                            </div>
                            <div
                              class="rounded-md cursor-pointer flex items-center justify-center hover:bg-gray-200 w-8 h-8"
                              @click="form.fields.splice(index, 1)"
                            >
                              <IconHawkTrashThree class="w-4 h-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ObjectElement>
                </template>
              </ListElement>
            </div>
            <div v-if="terra_store.check_permission('modify_workflows')" class="group flex items-center cursor-pointer text-primary-700 hover:bg-primary-50 w-28 rounded-lg p-2" :class="{ 'opacity-50 pointer-events-none': is_fields_100_percent }" @click="addField()">
              <IconHawkPlus
                class="text-primary-700 w-4 h-4 mr-2"
              />
              <div class="text-[14px] font-semibold group-hover:text-primary-800">
                {{ $t('Add field') }}
              </div>
            </div>
            <div class="flex text-[16px] font-semibold text-gray-700 mt-5 mb-2">
              {{ $t('Destination') }}
            </div>
            <ListElement
              name="steps"
              :rules="[
                'required',
              ]"
              :add-classes="{
                ListElement: {
                  list: 'bg-gray-50 rounded-lg',
                  listItem: 'px-4',
                },
              }"
              :controls="{
                add: false,
                remove: false,
              }"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                >
                  <div class="col-span-12">
                    <div class="grid grid-cols-12 my-1.5">
                      <div class="col-span-2 pr-2">
                        <TextElement
                          name="min"
                          input-type="number"
                          :placeholder="$t('Enter')"
                          default="0"
                          :rules="[
                            'integer',
                          ]"
                          autocomplete="off"
                          @input="$event => numberInputValidator($event, index, null, 'min')"
                          @blur="validateStepMin(index)"
                        >
                          <template #addon-after>
                            %
                          </template>
                        </TextElement>
                      </div>
                      <div class="col-span-1 font-semibold mt-2 text-center px-2">
                        -
                      </div>
                      <div class="col-span-2 px-2">
                        <TextElement
                          name="max"
                          input-type="number"
                          :placeholder="$t('Enter')"
                          :rules="[
                            'integer',
                          ]"
                          autocomplete="off"
                          @input="$event => numberInputValidator($event, index, null, 'max')"
                          @blur="validateStepMax(index)"
                        >
                          <template #addon-after>
                            %
                          </template>
                        </TextElement>
                      </div>
                      <div class="col-span-6 px-2">
                        <SelectElement
                          name="destination"
                          placeholder="Select"
                          :native="false"
                          :items="feature_types"
                          :rules="[
                            'required',
                          ]"
                          :add-classes="{
                            SelectElement: {
                              select: {
                                dropdown: '!overflow-y-auto',
                                options: '!none !hidden !h-0',
                                noOptions: '!none !hidden !h-0',
                                noResults: '!none !hidden !h-0',
                              },
                            },
                            ElementLayout: {
                              outerWrapper: '!block',
                            },
                            ElementLabel: {
                              container: '!mb-2.5',
                              wrapper: 'font-medium',
                            },
                          }"
                        >
                          <template #before-list="{ el$ }">
                            <FeatureTypes
                              type="dropdown"
                              @select="($event) => {
                                form.steps[index].destination = $event.uid;
                                el$?.input?.close();
                              }"
                            />
                          </template>
                          <template #single-label="{ value }">
                            <div class="flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3">
                              <div
                                class="cursor-pointer w-3 h-3 rounded-full bg-gray-400"
                                :style="getStyles(value.feature)"
                              />

                              <p
                                class="cursor-pointer pointer-events-auto whitespace-nowrap text-[13px] text-gray-600 ml-2"
                              >
                                <HawkText :content="value.label" :length="40" />
                              </p>
                            </div>
                          </template>
                        </SelectElement>
                      </div>
                      <div class="col-span-1 px-2">
                        <div class="flex items-center justify-end">
                          <div
                            class="rounded-md cursor-pointer flex items-center justify-center hover:bg-gray-200 w-9 h-9"
                            @click="form.steps.splice(index, 1)"
                          >
                            <IconHawkTrashThree class="w-4 h-4" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ObjectElement>
              </template>
            </ListElement>
          </template>
          <div
            v-if="terra_store.check_permission('modify_workflows')"
            class="group flex items-center cursor-pointer mt-2 text-primary-700 hover:bg-primary-50 w-28 rounded-lg p-2"
            :class="{ 'opacity-50 pointer-events-none': form.steps.length && form.steps.slice(-1)[0].max === 101 }"
            @click="addStep()"
          >
            <IconHawkPlus
              class="text-primary-700 w-4 h-4 mr-2"
            />
            <div class="text-[14px] font-semibold group-hover:text-primary-800">
              {{ $t('Add step') }}
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <!-- Footer -->
            <div class="flex justify-end items-center">
              <hawk-button
                class="mr-5"
                type="outlined"
                @click="$emit('close')"
              >
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :disabled="is_disabled" :submits="true">
                {{ is_edit ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
